<template>
  <div class="is-inline-block">
    <label class="label is-size-7">Options keyword</label>
    <div class="columns is-multiline is-vcentered">
      <div class="column is-narrow">
        <div class="field">
          <div class="control">
            <form @submit.prevent="add">
              <input
                type="text"
                class="input"
                :class="{ 'is-danger': errors.first('keyword') }"
                name="keyword"
                :disabled="maximum"
                v-validate="{ regex: /^[\w\-\,\s]+$/ }"
                data-vv-validate-on="input"
                v-model="value"
              />
            </form>
          </div>
        </div>
      </div>
      <div class="column">
        <transition-group
          v-if="values && values.length > 0"
          class="field is-grouped is-grouped-multiline"
          name="flip-list"
          tag="div"
        >
          <div
            class="control"
            v-for="(value, index) in values"
            :key="`key-${index}`"
          >
            <div class="field has-addons is-marginless">
              <div class="tags has-addons">
                <a class="tag is-soft has-text-weight-bold">{{ value }}</a>
                <a
                  class="tag is-delete has-text-danger"
                  @click="clearFilter(index)"
                />
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <p class="help is-danger" v-if="maximum">
      You may only search a maximum of 5 terms
    </p>
    <p class="help" v-else>
      To search for multiple terms, use a comma delimited list:
      <code>xenon,leather</code>
    </p>
  </div>
</template>

<script>
import { ga } from '@/plugins/tracking'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'SimilarListingsKeyword',
  data: () => ({ value: '' }),
  computed: {
    ...mapGetters({
      loading: 'companion/similarListings/loading',
      values: 'companion/similarListings/filterBy/keywordFilter'
    }),
    maximum() {
      return this.values && this.values.length === 5
    }
  },
  methods: {
    add() {
      this.$validator.validate().then(result => {
        if (result) {
          if (!this.maximum && this.value) {
            this.setFilter({
              type: 'keyword',
              filter: this.value,
              push: true
            })

            ga({
              eventAction: this.value,
              eventCategory: 'Companion',
              eventLabel: 'Options Filter'
            })

            this.$mxp.track('options_keyword_search', { term: this.value })

            this.value = ''
          }
        }
      })
    },
    clearFilter(index) {
      this.$mxp.track('delete_options_keyword')
      this.clearKeyword({ index })
    },
    ...mapActions({
      clearKeyword: 'companion/similarListings/filterBy/clearKeyword',
      setFilter: 'companion/similarListings/filterBy/updateFilter'
    })
  }
}
</script>

<style lang="sass">
.flip-list-enter-active, .flip-list-leave-active
  transition: all .5s
.flip-list-enter, .flip-list-leave-to
  opacity: 0
  transform: translateX(30px)
.flip-list-move
  transition: transform .5s ease
</style>
